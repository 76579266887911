import React from 'react';
import BottomPageComponent from './BottomPageComponent';

function Footer({ categories }) {
  return (
    <div className='relative -mt-[130px] z-0'>
      <BottomPageComponent categories={categories} />

      <footer className='bg-deeperblue text-white w-full bottom-0 text-sm absolute p-5'>
        <div className='container mx-auto px-4 flex flex-col md:flex-row justify-between items-center'>
          <div className='mb-4 md:mb-0 flex items-center text-gray-200'>
            <div className='p-10 py-2'>
              <p>Heroics Capital</p>
              <p className='text-gray-500'>34A Rue Philippe II - L2340 Luxembourg - RCS B274980</p>
              <p className='text-gray-500'>57 Rue Grimaldi - 98000 Monaco - RCI 23S09652</p>
            </div>
          </div>
          <ul className='text-gray-400'>
            {categories.map((category, index) => (
              <li key={index} className='p-1'>
                <a href={category.path} className=''>
                  {category.name}
                </a>
              </li>
            ))}
          </ul>

          <div className='flex items-center justify-center my-3'>
            <div className='flex items-center w-full'>
              <div className='flex flex-col items-center justify-center my-3'>
                <div className='flex items-center justify-between w-full max-w-xs'>
                <h3 className='flex items-center pt-1 text-sm font-bold mb-2 p-0 mr-2'>
                    <a href='https://linkedin.com/in/heroics-capital-21499527b/en'>
                      Follow Us
                    </a>
                  </h3>

                  <a
                    href='https://linkedin.com/in/heroics-capital-21499527b/en'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src='/img/linkedin.png'
                      alt='LinkedIn'
                      className='w-8 h-8'
                    />
                  </a>

                </div>
                <div className='flex justify-content'>
                  <h3 className='flex items-center pt-1 text-sm font-bold mb-2 p-0 mr-2'>
                    <a href='https://heroics-capital.com/'>
                      Heroics Monaco
                    </a>
                  </h3>

                  <a
                    href='hhttps://heroics-capital.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      src='/img/worldLink.png'
                      alt='Heroics Capital Monaco'
                      className='w-8 h-8'
                    />
                  </a>
                </div>

              </div>

            </div>
          </div>
          <div className='flex flex-col items-center text-gray-600 px-5'>
            <p className=''>
              &copy; {new Date().getFullYear()} Heroics Capital Partners. All rights
              reserved.
            </p>
            <a
              href='https://fr.linkedin.com/in/paul-zamanian-abbassi-899126196'
              className='text-deeperblue'
            >
              Website made by Paul Zamanian, Click for LinkedIn
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
